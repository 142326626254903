@import "src/variable/colors.scss";
.walletPage {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;

  .walletPageWelCard {
    display: flex;
    width: 100%;
    background: rgb(156, 25, 121);
    background: rgb(139, 212, 247);
    background: linear-gradient(122deg, rgb(255 255 255) 0%, rgb(106, 72, 155) 100%);
  }

  .userPageHead {
    display: flex;
    margin: 24px;
    gap: 15px;
    margin-bottom: 0;

    .infoCard,
    .preferenceCard {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      margin: 10px 0px 19px 12px;
      flex: 1 0 0;
      border-radius: 12px;
      border: 1px solid $gray-300;
      background: $primary-white;
      min-width: 23vw;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        .headL {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px;
          h3 {
            color: $dark;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }
          p {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .headR {
          display: flex;
          gap: 8px;
          .editIconContainer {
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            border: 1px solid $gray-300;
            background: $primary-white;
            box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
            cursor: pointer;

            .editIcon {
              width: 14px;
              height: 14px;
              color: #64748b;
            }
          }
          .merchantIcon {
            display: flex;
            align-items: center;
            border: 1px solid $gray-300;
            background: $primary-white;
            box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
            cursor: pointer;
            padding: 6px;
            border-radius: 6px;
            .icon {
              width: 18px;
              height: 18px;
              color: #64748b;
            }
          }
        }
        Button {
          background-color: $dark;
        }
      }
      .walletInfo,
      .preferenceInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        width: 100%;
        .label {
          display: flex;
          align-items: center;
          gap: 6px;

          @include labelInput;
          label {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
          .value {
            color: $dark;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          input {
            padding: 6px 10px;
          }
        }

        .allowedLimit,
        .spendLimit {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          .label {
            input {
              border: none;
              width: 50px;
            }

            .value {
            }
          }

          .sliderInput {
            display: flex;
            justify-content: space-between;
          }
          .slider {
            display: flex;
            flex-direction: column;
            .maxmin {
              font-size: 12px;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .mccCategory {
          display: flex;
          gap: 6px;
          align-items: center;
          width: 100%;

          .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          label {
            color: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
          input {
            border: none;
            // width: -webkit-fill-available;
          }
        }

        .error {
          display: flex;
          color: rgb(254, 44, 44);
          gap: 4px;
          font-size: 12px;
          font-style: normal;
          line-height: 20.8px;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          margin-top: -6px;
          margin-bottom: -7px;

          .errIcon {
            width: 15px;
          }
        }
      }
    }
    .settingCard {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      padding: 20px;
      //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin-top: 10px;
      margin-bottom: 20px;

      h1 {
        margin-bottom: -1px;
        color: $dark;
        margin-top: -5px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
      }

      p {
        font-size: 14px;
        color: #64748b;
        margin: 5px 0;
      }

      .depositDetails {
        margin-top: 22px;
        margin-bottom: 45px;

        .fRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .item {
            flex: 1;
            font-size: 14px;
            &:nth-child(2) {
              flex: 0.1;
            }
            &:nth-child(3) {
              flex: 2;
            }
          }
        }
      }
    }
    .connectleftCard {
      margin: 10px 0px 20px 10px;
      width: 50%;
      display: flex;
      //margin-right: 60px;
      // margin: 10px;
      // margin-bottom: 20px;
    }
  }

  .userPageMid {
    display: flex;
    margin: 0px 24px;
    gap: 10px;
    margin-bottom: 0;
    flex-wrap: wrap;
    .connectleftCard {
      margin: 10px 0px 20px 10px;
      // width: 50%;
      display: flex;
      flex: 1 1 0;
      //margin-right: 60px;
      // margin: 10px;
      // margin-bottom: 20px;
    }

    .connectCard {
      margin: 10px 0px 20px 10px;
      // width: 50%;
      display: flex;
      flex: 1 1 0;
      // margin: 10px;
      // margin-bottom: 20px;
    }
    .blockedMerchant {
      margin: 10px 0px 20px 10px;
      // width: 50%;
      display: flex;
      flex: 1 1 0;
    }

    .settingCard,
    .visaCardContainer {
      flex: 1 1 0;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      padding: 20px;
      margin: 12px;
      margin-right: 0px;

      h1 {
        margin-bottom: -1px;
        color: $dark;
        margin-top: -5px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
      }

      p {
        font-size: 14px;
        color: #64748b;
        margin: 5px 0;
      }
    }
    .settingCard {
      .depositDetails {
        margin-top: 22px;
        margin-bottom: 45px;

        .fRow {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .item {
            flex: 1;
            font-size: 14px;
            &:nth-child(2) {
              flex: 0.1;
            }
            &:nth-child(3) {
              flex: 2;
            }
          }
        }
      }
    }

    .visaCardContainer {
      padding-bottom: 0px;
      .card {
        display: flex;
        justify-content: center;
      }
    }
  }

  .userPageBottom {
    display: flex;
    margin: 24px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 1330px) {
  .walletPage {
    .userPageHead {
      .infoCard,
      .settingCard,
      .preferenceCard {
        min-width: 180px;
      }
      .transCard {
        .WalletTransCardOver {
          .balCardOver {
            // width: 35vw;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1220px) {
  .walletPage {
    .userPageBottom {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 900px) {
  .walletPage {
    .userPageHead {
      flex-wrap: wrap;

      .infoCard,
      .settingCard,
      .preferenceCard {
        min-width: 250px;
        // margin: 10px 0px 0px 12px;
      }

      .settingCard {
        width: 100%;
        max-width: 100%;
      }

      .connectleftCard {
        width: 100%;
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .walletPage {
    .userPageMid {
      flex-direction: column;
      gap: 15px;
      margin: 24px;

      .connectCard {
        width: 99%;
        margin: 10px 10px 0px 10px;
      }
      .connectleftCard {
        width: 99%;
        margin: 10px 10px 0px 10px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .walletPage {
    .userPageMid {
      flex-direction: column;
      gap: 5px;
      .connectCard {
        width: auto;
        display: flex;
      }
      .connectleftCard {
        width: auto;
        display: flex;
      }
      // .depositCard {
      //   margin: 10px 0px 19px 12px;
      //   width: auto;
      // }
      .transCard {
        .WalletTransCardOver {
          .balCardOver {
          }
        }
      }
    }
  }
}
