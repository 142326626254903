@import "../../../variable/colors.scss";

.referModalBox {
  display: flex;
  flex-direction: column;

  .referModalTop {
    margin: 24px 40px;
    margin-right: 80px;

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      box-sizing: border-box;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
          margin-right: -40px;
        }
      }

      .lineII,
      .lineIII,
      .lineIV,
      .kycrow,
      .genderNdob {
        display: flex;
        justify-content: space-between;
        gap: 80px;
        flex-wrap: wrap;

        .addressline1,
        .addressline2,
        .city,
        .state,
        .postCode,
        .kyc,
        .guardian,
        .initial,
        .gender,
        .dob,
        .email {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          @include labelInput;
          box-sizing: border-box;

          input {
            width: 100%;
          }

          select {
            width: 100px;
          }
        }

        .guardian {
          .guardianInput {
            input {
              border: none;
              margin-top: -7px;

              &:hover {
                border: none;
              }
            }
          }
        }

        .mobile {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          @include labelInput;
          box-sizing: border-box;

          .mobileSelect {
            display: flex;
            gap: 5px;
            input {
              width: 100%;
            }

            select {
              width: 100px;
            }
          }
        }

        .role,
        .guardian,
        .kyc {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          box-sizing: border-box;
          @include labelInput;

          .disableRoleInput {
            @include labelInput;
            input {
              width: 100%;
              background-color: #e2e2e2a3;
            }
          }

          .autoComplete {
            input {
              border: none;
              width: 100%;
              padding: 5px;
            }
          }
        }
      }

      .name {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .nameContainer {
          flex: 1;
          display: flex;
          justify-content: space-between;
          gap: 50px;
          flex-wrap: wrap;

          .Fname,
          .Lname {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include labelInput;
            box-sizing: border-box;

            input {
              width: 100%;
            }
          }
        }
        .initial {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }

      .lineIII .address {
        flex: 3;
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .referModalBox {
    max-width: 90%;
    min-width: 350px;
    max-height: 90vh;
    overflow-y: auto;

    .referModalTop {
      form {
        gap: 12px;

        .name,
        .lineII,
        .lineIII,
        .lineIV,
        .kycrow {
          flex-direction: column;
          gap: 16px;

          .Fname,
          .Lname,
          .mobile,
          .address,
          .city,
          .state,
          .postCode,
          .kyc,
          .guardian {
            input,
            .autoComplete {
              width: 90%;
            }
          }
        }
      }
    }

    .referModalBottom {
      .sendBtn {
        width: 100px;
        margin-top: 10px;
        margin-right: 77px;
      }
    }
  }
}
