@import "src/variable/colors.scss";

.modalBody {
  .details {
    background: rgba(#d3dae6, 40%);
    margin: 1rem 0;
    border-radius: 6px;
    padding: 1rem;
    .fRow {
      display: flex;
      .item {
        padding: 5px 0;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 25px;
        }
        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
    + p {
      margin-bottom: 1rem;
    }
  }
  .note {
    opacity: 0.6;
  }

  .walletDetail {
    margin-top: 15px;
  }
  .bckBtn {
    display: flex;
    margin-bottom: 19px;
    justify-content: flex-end;
    gap: 70px;
    button {
      background-color: $dark;
      color: #fff;
      text-transform: none;
    }
  }
  .participantselect {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .fundBtn {
      background-color: $dark;
      color: #fff;
      text-transform: none;
    }

    .modalMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 22px;

      .modalHeader {
        font-size: 22px;
        font-weight: bold;
      }
      .addFundBody {
        font-size: 16.75px;
        text-align: start;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .error {
    display: flex;
    color: rgb(254, 44, 44);
    gap: 4px;
    font-size: 12px;
    font-style: normal;
    line-height: 20.8px;
    align-items: center;

    .errIcon {
      width: 15px;
    }
  }
}
