@import "src/variable/colors.scss";

.columnSettingBtn {
  display: flex;
  height: 100%;
  align-items: flex-end;
  width: 100%;
  color: #6f727a;
  justify-content: flex-end;
  .icon {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
}

.columnFilter {
  display: flex;
  flex-direction: column;
  .columnFilterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 20px;
    h2 {
      color: $primary-black;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    button {
      border: none;
      background-color: $primary-white;
      cursor: pointer;
    }
  }
  .columnFilterBody {
    display: flex;
    flex-direction: column;

    .columnFilterSelect {
      padding: 0px 20px;
      display: flex;
      gap: 20px;
      justify-content: flex-end;

      button {
        background-color: $dark;
        text-transform: none;
      }
    }
    .columnFilterList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px 20px;
      padding: 20px;
      width: 50vw;

      .columnFilterItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin-bottom: 10px;

        &:nth-child(odd) {
          margin-right: 2%;
        }

        .MuiFormControlLabel-label {
          flex: 1;
          margin-right: 10px;
        }

        .MuiSwitch-root {
          margin: 0;
        }
      }
    }
    .columnFilterBtn {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      background: $secondary-gray-1;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      button {
        background-color: $dark;
        text-transform: none;
      }
    }
  }
}
