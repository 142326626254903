@import "../../variable/colors.scss";

.userList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  border-radius: 12px;
  border: 1px solid $gray-200;
  background: $primary-white;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

  .userListHead {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;

    .userListHeadIn {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .userListHeadD {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        h3 {
          color: $dark;

          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
        }

        p {
          color: $secondary;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .userListHeadBtn {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .filterBtn {
          height: 35px;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $gray-500;
          background: $primary-white;
          // margin: 0px 16px;

          color: $dark;
          /* Xs bold */
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: none;
        }

        .addUserBtn {
          display: flex;
          height: 35px;
          background: $dark;
          border: radius 6px;
          align-items: center;

          color: $primary-white;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }
    }

    .userListFilter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      Button {
        @include outlineBtn;
      }

      .filterSearch {
        display: flex;
        gap: 15px;
      }

      .userListSearch {
        display: flex;
        gap: 5px;

        .searchBar {
          height: 40px;
          width: 290px;
        }
      }
    }
  }

  .userListTable {
    display: flex;
    width: 100%;
    overflow-x: auto;

    .tableIn {
      width: 100%;
      table {
        width: 100%;
        border: 1px solid $gray-300;

        border-collapse: collapse;

        thead {
          // display: flex;
          width: 689px;
          height: 40px;
          border: 1px solid $gray-300;
          background: $gray-100;
          background: $gray-100;

          tr th {
            color: #4b5563;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0px 20px;
            background-color: #f9fafb;
          }
        }
      }

      tbody {
        tr {
          width: 689px;
          padding: 0px 20px;
          border: 1px solid var(--gray-400, #ced4da);

          td {
            width: 689px;
            padding: 9px 20px;

            text-align: left;
            color: $secondary;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            .tableMember {
              display: flex;
              align-items: center;
              gap: 12px;
              flex: 1 0 0;
              .tableMemberImg {
                img {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                }
              }

              .tableMemberDetail {
                display: flex;
                padding: 0px 12px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                .tableMemberName {
                  align-self: stretch;
                  color: $dark;

                  /* h6 */
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 162.5%; /* 26px */
                  letter-spacing: -0.8px;
                }
                .tableMemberEmail {
                  align-self: stretch;
                  color: $secondary;

                  /* Small */
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                }
              }
            }

            .tableRole {
              .tableRoleName {
                display: flex;
                align-items: center;
                border-radius: 6px;
                border: 1px solid;
                color: $dark;
                max-width: 100px;
                width: auto;
                min-height: 23px;

                /////text///////
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .tableroleText {
                color: $secondary;
                /* Small */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 21px */
              }
            }

            .tableStatus {
              // display: flex;
              // width: 200px;
              padding: 8px 0px;
              gap: 8px;
              .green {
                display: flex;
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgb(49 92 28 / 64%);
                background: rgb(103 194 58 / 7%);
                width: 50px;
                height: 23px;
                color: #345f1e;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .grey {
                display: flex;
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgb(100 116 139);
                background: rgba(100, 116, 139, 0.1);
                width: 63px;
                height: 23px;
                color: #303843;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .yellow {
                display: flex;
                /* padding: 4px 4px; */
                align-items: center;
                border-radius: 6px;
                border-radius: 6px;
                border: 1px solid rgba(100, 116, 139, 0.45);
                background: rgba(215, 156, 4, 0.37);
                width: 89px;
                height: 23px;

                /////text///////
                color: $secondary;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
            }

            .tableLastActive {
              display: flex;
              width: 200px;
              padding: 8px 0px;
              align-items: flex-start;
              gap: 8px;

              color: $secondary;
              text-align: center;

              /* Small */
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
      }
    }
  }
  .paginationWrapper {
    display: flex;
    justify-content: center;
    // margin-top: 10px;
  }

  //   }
}

.addUserModal {
  width: 70% !important;
}

@media screen and (max-width: 800px) {
  .userList {
    .userListHead {
      .userListHeadIn {
        .userListHeadD {
          h3 {
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }
        }
        .userListHeadBtn {
          .addUserBtn {
            height: 28px;

            font-size: 10px;
          }
        }
      }

      .userListFilter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .userListSearch {
          width: auto;
        }
      }
    }

    .userListTable {
      tbody {
        tr {
          td {
            .tableMember {
              .tableMemberDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableMemberEmail {
                  font-size: 10px;
                }
              }
            }

            .tableRole {
              .tableRoleName {
                font-size: 13px;
              }
              .tableroleText {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .userList {
    .userListHead {
      .userListFilter {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 10px;

        .filterSearch {
          width: 100%;
          justify-content: space-between;
        }

        .userListSearch {
          height: 35px;
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .searchBar {
            height: 40px;
            width: auto;
          }
        }
      }
    }
  }

  .addUserModalOver {
    width: 95% !important;
  }
}
