@import "src/variable/colors.scss";

.referModalBox {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .referModalTop {
    display: flex;
    padding: 16px 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;

    justify-content: space-between;

    .topL {
      display: flex;
      padding-top: 0px;
      P {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }

    .topR {
      display: flex;
      gap: 8px;
      .closeBtn {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      .requestBtn {
        text-transform: none;
        color: $dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }

  .skeleton {
    margin: 0px 55px;
    width: 30vw;
    border-radius: 8px;
  }

  .referModalBottom {
    display: flex;
    flex-direction: column;
    margin: 0px 55px;
    gap: 24px;

    .wallet {
      display: flex;
      flex-direction: column;

      .blockMessage {
        padding: 16px;
        background-color: #f8f9fa;
        border-radius: 4px;
        margin: 16px 0;
        color: #666;

        button {
          background: none;
          border: none;
          color: #1976d2;
          cursor: pointer;
          padding: 0;
          font: inherit;
          text-decoration: underline;

          &:hover {
            color: #0f4c8a;
          }
        }
      }

      .label {
        color: $secondary-gray-4;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      input {
        border: none;
      }

      label {
        color: rgba(111, 114, 122, 0.8);
      }
    }

    .merchant {
      display: flex;
      flex-direction: column;
      border: 1px solid $secondary-gray-2;
      border-radius: 12px;
      padding: 12px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

      .merchantItem {
        display: flex;
        align-items: center;
        gap: 12px;

        .label {
          color: $secondary-gray-4;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          flex: 2;
        }

        .colon {
          color: $secondary-gray-4;
          flex: 1;
        }

        .value {
          color: $dark;
          flex: 1;
          text-align: right;
          font-size: 14px;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      border: 1px solid $secondary-gray-2;
      border-radius: 12px;
      padding: 12px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

      .userAvatar {
        width: 55px;
        height: 55px;
      }

      .userDetail {
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        .userName {
          align-self: stretch;
          color: $dark;

          /* h6 */
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%; /* 26px */
          letter-spacing: -0.8px;
        }
        .userEmail,
        .userId {
          align-self: stretch;
          color: $secondary;

          /* Small */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
    }
  }

  .footer {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .modalbtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}
