@import "src/variable/colors.scss";
.container {
  display: flex;
  flex-direction: column;
  // margin: 33px 15px 15px 25px;
  gap: 16px;
  background: $gray-100;
  .heading {
    h1 {
    }
  }

  .paymentContainer {
    display: flex;
    flex-direction: column;
    width: 315px;
    gap: 30px;

    .removeBtnContainer {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .removeBtn {
        display: flex;
        padding: 6px;
        background-color: rgb(246, 48, 48);
        color: #e8e6e6;
        font-weight: 600;
        margin-top: 10px;
        // text-transform: none;
      }
    }

    .anotherBtn {
      // margin: 0px 10px;
      background-color: #fb8278;
      color: #000;
      border-radius: 10px;
      font-weight: 500;
    }

    .totalCardContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .editAmountBtn {
        margin: 0px 5px;
        border-radius: 10px;
        background-color: #ffffff;
        border: 1px solid #888888bb;
        color: #000;
        font-weight: 600;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      // margin: 0px 10px;
      gap: 20px;
      .backBtn,
      .continueBtn {
        font-size: 16px;
        font-weight: 700px;
        height: 56px;
        width: 100%;
        background-color: $dark;
        border-radius: 10px;
      }
      .backBtn {
        background-color: #d9d9d9;
        color: #000;
      }
    }
  }

  .newPaymentButton {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 33px 15px 15px 25px;
    .singlePayOp,
    .groupPayOp,
    .moneyBtwWallet {
      display: flex;
      background-color: #ffffff;
      border: 1px solid;
      border-color: #0000004c;
      border-radius: 10px;
      box-shadow: 0px 4px 4px #00000040;
      flex: 0 0 auto;
      gap: 8px;
      padding: 14px 13px;
      width: fit-content;
      align-items: center;
      min-width: 255px;
      cursor: pointer;

      .p {
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    width: 80%;

    .paymentContainer {
      width: 75vw;
    }
  }
}
