@import "src/variable/colors.scss";

.uploadFileWrapper {
  padding: 25px;

  .WelCard {
    display: flex;
    margin-bottom: 25px;
    height: 140px;
  }

  .uploadCard {
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 12px;
    box-shadow: 0 1px 2px 0 #33415614;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto;
    padding: 16px 0;
    width: 100%;

    .uploadType {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $secondary-gray-4;
      margin: 0px 33px;

      .head {
        color: $dark;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .emailInputContainer {
        textarea {
          display: flex;
          width: -webkit-fill-available;
          border-radius: 4px;
          padding: 10px;
          border: 1px solid #c4c4c4;
          margin-top: 19px;
          font-size: 14px;
          font-family: inherit;
          letter-spacing: inherit;
        }
      }

      .fileUploadContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .uploadFile {
          height: 8rem;
          margin: 15px 0px;
          width: -webkit-fill-available;
          display: flex;
          min-width: auto;
          max-width: -webkit-fill-available;
        }
      }
    }
  }

  .selectOption {
    display: flex;
  }

  .actionButton {
    display: flex;
    gap: 40px;
    margin: 0px 33px;

    .downloadBtn {
      border-radius: 8px;
      justify-content: center;
      color: $dark;
      text-align: center;
      /* Body / Bold */
      // font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
      flex: 1;
    }
    .uploadBtn {
      display: flex;
      height: 48px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: $dark;
      font-weight: 600;
      line-height: 24px;
      text-transform: none;
      flex: 1;
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
