@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .setting {
        display: flex;
        // width: 675px;
        padding: 0px 31px 0px 13px;
        align-items: flex-start;
        gap: 20px;
        flex-direction: column;
        .settingR {
          display: flex;
          //   width: 360px;
          align-items: center;
          gap: 12px;
          flex-shrink: 0;
          .image {
            img {
              width: 32px;
              height: 32px;
              flex-shrink: 0;
              border-radius: 50%;
            }
          }

          .supportInfo {
            display: flex;
            padding: 0px 12px;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            h5 {
              color: var(--dark, #1e293b);

              /* h6 */
              //   font-family: Noto Sans;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 162.5%; /* 26px */
              letter-spacing: -0.8px;
            }

            p {
              color: var(--secondary, #64748b);

              /* Small */
              //   font-family: Noto Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }
          }
        }
        .RadioInput {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          label {
            color: $secondary-gray-4;
            /* Secondary / Semibold */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          .inputs {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            .inputYes {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 4px;
              border-radius: 4px;
              background: $primary-white;

              label {
                color: var(--text-666-e-80, #666e80);
                text-align: right;

                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .inputNo {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 4px;
              border-radius: 4px;
              background: $primary-white;

              label {
                color: var(--text-666-e-80, #666e80);
                text-align: right;

                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }

        .setetingL {
          display: flex;
          padding: 0px 12px;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          h4 {
            color: var(--dark, #1e293b);

            /* h6 */
            //   font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 162.5%; /* 26px */
            letter-spacing: -0.8px;
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btnL {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .removeBtn {
          display: flex;
          /* height: 40px; */
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fa0000;
          box-shadow:
            0px 4px 8px 0px rgba(163, 59, 8, 0.08),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #fa0000;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }

        .reportBtn {
          color: #324056;
          text-align: center;

          /* Body / Bold */

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: none;
        }
      }

      .btnR {
        .sendBtn {
          display: flex;
          /* height: 48px; */
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #1e293b;

          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
