.onboarding {
  display: flex;
  height: 100vh;
  background: linear-gradient(121deg, rgba(0, 83, 163, 0.9) 14.94%, rgba(31, 62, 171, 0.77) 30.35%, #f9847c 91.36%);

  .onBoardingLayout {
    display: flex;
    flex-direction: row;
    width: 100%;

    .onBoardingNav {
      width: 296px;
      // height: 100vh;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      left: 0;
      z-index: 9 !important;

      .navContainer {
        display: flex;
        background-color: #0f172a;
        // justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .navBottomLogo {
          position: relative;
          bottom: 0;
          margin: 25px;
        }

        .navBottomLogo img {
          width: 194px;
          height: 98.8px;
        }

        .onBoardingNavText {
          color: #ffffff;
          font-family: "Nunito-Bold", Helvetica;
          font-size: 16px;
          font-weight: 700;
          height: 31px;
          letter-spacing: 0;
          line-height: normal;
          text-align: center;
          width: 100%;
          //margin-top: 27px;
        }

        .navigButton {
          display: flex;
          position: relative;
          gap: 22px;
          height: 100%;
          //margin: 0px auto 30px;
          align-items: flex-end;
          margin-bottom: 10px;
          left: 15px;
          .logoutBtn,
          .homeBtn {
            display: flex;
            padding: 5px;
            border-radius: 5px;
            justify-content: center;
            background-color: #ffffff;
            border: 1px solid #dde0e5;
            box-shadow: 0px 1px 18px 20px rgba(51, 65, 86, 0.08);
            height: fit-content;
            cursor: pointer;
          }
        }
      }
    }

    .onBoardingContent {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .onBoardingForm {
        display: flex;
        background-color: #ffffff;
        border-radius: 12px;
        border: 1px solid #dde0e5;
        box-shadow: 0px 1px 18px 20px rgba(51, 65, 86, 0.08);
        .addOrgOver,
        .addParticipantOver {
          width: 60vw;
        }

        .addWalletOver {
          width: 60vw;
        }
      }
    }
  }
}

.modaluiOver {
  box-shadow: none;

  &:focus {
    outline: none;
  }
}

.modalShadowOver {
  background-color: #ffffff00;
}

.popUpLayout {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 40px;

  .textWrapper {
    color: #232325;
    font-family: "Inter-Bold", Helvetica;
    font-size: 30px;
    font-weight: 700;
    height: 31px;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 569px;
    text-align: center;
  }

  .areYouTheFirst {
    color: #000000;
    font-family: "Noto Sans-SemiBold", Helvetica;
    font-size: 13px;
    font-weight: 600;
    height: 68px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 585px;
  }

  .selectButtons {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    gap: 5%;
    justify-content: space-evenly;
    width: 100%;

    .divWrapper {
      height: 200px;
      position: relative;
      width: 179px;
      cursor: pointer;

      .div {
        align-items: flex-start;
        background-color: #f9847ccc;
        border: 0.5px solid;
        border-color: #00000080;
        border-radius: 8px;
        box-shadow: 0px 4px 4px #00000040;
        display: inline-flex;
        gap: 10px;
        overflow: hidden;
        padding: 13px 14px;
        position: relative;

        .rectangle {
          background-color: #ffffff;
          border: 0.5px solid;
          border-color: #00000080;
          border-radius: 8px;
          box-shadow: 0px 4px 4px #00000040;
          height: 174px;
          position: relative;
          width: 151px;
        }

        .textWrapper2 {
          color: #1e293b;
          font-family: "Nunito-Bold", Helvetica;
          font-size: 13px;
          font-weight: 700;
          left: 18px;
          letter-spacing: 0;
          line-height: normal;
          position: absolute;
          text-align: center;
          top: 143px;
          width: 139px;
        }

        .businessBuilding {
          height: 70px;
          left: 52px;
          position: absolute;
          top: 42px;
          width: 75px;
        }
      }
    }
  }

  .onBoardingLayout {
    display: flex;
    flex-direction: row;

    .onBoardingNav {
      width: 226px;
      height: 100vh;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      left: 0;
      z-index: 9 !important;
    }

    .onBoardingContent {
    }
  }
}

.navigButton {
  display: flex;
  gap: 12px; /* Adjust gap as needed */
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust bottom margin if needed */
}

.logoutBtn,
.homeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  padding: 8px;
  border-radius: 50%; /* Make it circular */
  background-color: #ffffff;
  border: 1px solid #dde0e5;
  box-shadow: 0px 4px 8px rgba(51, 65, 86, 0.08);
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a hover effect */
}

.logoutBtn:hover,
.homeBtn:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}

.onboarding {
  display: flex;
  height: 100vh;
  background: linear-gradient(121deg, rgba(0, 83, 163, 0.9) 14.94%, rgba(31, 62, 171, 0.77) 30.35%, #f9847c 91.36%);
  overflow: hidden;
}

.onBoardingLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh; /* Ensures it fills the viewport height */
  align-items: stretch; /* Ensures full height alignment */
}

.onBoardingNav {
  width: 296px;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 9;
}

.onBoardingContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; /* Remove any unexpected margins */
  padding: 0; /* Remove any unexpected padding */
}

.onBoardingForm {
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #dde0e5;
  box-shadow: 0px 1px 18px 20px rgba(51, 65, 86, 0.08);
  //width: 60vw; /* Adjust width as needed */
}

@media (max-width: 800px) {
  .onBoardingLayout {
    flex-direction: column;
  }

  .onBoardingNav,
  .onBoardingContent {
    width: 100%;
  }

  .onBoardingNav {
    height: auto;
  }

  .navContainer,
  .popUpLayout,
  .selectButtons .divWrapper .div {
    font-size: smaller;
  }

  .navContainer {
    width: auto;
  }

  .navBottomLogo img {
    width: 150px;
    height: auto;
  }
}

@media (max-width: 600px) {
  .popUpLayout,
  .selectButtons .divWrapper .div {
    padding: 20px 10px;
  }

  .textWrapper,
  .areYouTheFirst,
  .textWrapper2 {
    font-size: smaller;
  }

  .selectButtons {
    flex-direction: column;
    gap: 10px;
  }

  .navContainer {
    width: auto;
  }

  .selectButtons .divWrapper {
    width: 100%;
  }
}
