@import "../../variable/colors.scss";

.transactionList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  border-radius: 12px;
  border: 1px solid $gray-200;
  background: $primary-white;
  /* Shadow cards */
  box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
  //   margin: 10px;

  .transactionListHead {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;

    .transactionListHeadIn {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .transactionListHeadD {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        h3 {
          color: $dark;

          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 162.5%;
          letter-spacing: -0.8px;
        }

        p {
          color: $secondary;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .transactionListHeadBtn {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .filterBtn {
          height: 35px;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $gray-500;
          background: $primary-white;
          // margin: 0px 16px;

          color: $dark;
          /* Xs bold */
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: none;

          .transRecordBtn {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .addTransactionBtn {
          display: flex;
          gap: 7px;
          min-width: 173.5px;
          height: 35px;
          background: $dark;
          border: radius 6px;
          align-items: center;

          color: $primary-white;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }
    }

    .transactionListFilter {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      // height: 40px;

      select {
        display: flex;
        width: 210px;
        height: 40px;
        padding: 0px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #ced4da;
        background: $primary-white;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

        color: var(--text-666-e-80, #666e80);
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        option {
          color: var(--text-666-e-80, #666e80);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .filterTagsContainer {
        display: flex;
        // height: 50px;
        max-width: 60%;
        flex-wrap: wrap;
        // overflow-y: auto;
        gap: 10px;
      }

      .tag {
        display: flex;
        align-items: center;
        border: 1px solid;
        padding: 6px 10px;
        border-radius: 6px;
        height: 13px;
        background-color: #007bff33;
        border: 1px solid #007bff73;

        label {
          font-weight: 500;
          font-size: 12px;
        }
      }

      .transactionListSearch {
        // height: 40px;
        // width: 42vw;
        // display: flex;
        // gap: 8px;
        // justify-content: space-between;
        height: 40px;
        width: 290px;

        select {
          display: flex;
          width: 155px;
          height: 40px;
          padding: 0px 16px;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #ced4da;
          background: $primary-white;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

          color: var(--text-666-e-80, #666e80);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          option {
            color: var(--text-666-e-80, #666e80);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        // .searchBar {
        //   padding: 8px;
        // }
      }
    }
  }

  .transactionListTable {
    display: flex;
    width: 100%;
    overflow-x: auto;

    .tableIn {
      width: 100%;

      table {
        width: 100%;
        border: 1px solid $gray-300;

        border-collapse: collapse;

        thead {
          // display: flex;
          width: 689px;
          height: 40px;
          border: 1px solid $gray-300;
          background: $gray-100;
          background: $gray-100;

          tr th {
            color: #4b5563;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-align: left;
            padding: 0px 20px;
            background-color: #f9fafb;
          }
        }
      }

      tbody {
        tr {
          width: 689px;
          padding: 0px 16px;
          border: 1px solid $gray-400;

          td {
            width: 689px;
            padding: 9px 20px;

            text-align: left;
            color: $secondary;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            .tableMember {
              .tableMemberDetail {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .tableMemberName {
                  color: #1e293b;
                }
              }
            }

            .operation {
              cursor: pointer;
              display: flex;
              justify-content: flex-end;
            }
            .blockMerchant {
              cursor: pointer;
              display: flex;
              justify-content: flex-end;
            }

            .tableDate {
              display: flex;
              // width: 177px;
              flex: 1 0 0;
              gap: 4px;
              text-align: left;

              color: #17181a;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }

            .tableMerchent,
            .tableWallet,
            .activityStatus,
            .tableAmount,
            .activityname,
            .tableBalance {
              // color: $dark;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 162.5%;
              text-align: left;
            }

            .activityname {
              color: $dark;
            }

            .tableAmount {
              color: red;
            }

            .tableBalance {
              color: green;
            }
            .edit {
              display: flex;
              // width: 40px;
              padding: 8px;
              justify-content: center;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
    // margin-top: 10px;
  }
  //   }
}

.modaluiOver {
  width: 67vw !important;
  max-width: 58rem !important;
}

.filterModalOver {
  overflow-y: auto;
}

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .setting {
        display: flex;
        width: -webkit-fill-available;
        align-items: flex-start;
        gap: 10px;
        text-align: left;
        font-weight: 600;
        line-height: 150%;
        flex-wrap: wrap;
        margin-top: -4px;

        .tag {
          display: flex;
          align-items: center;
          border: 1px solid;
          padding: 6px 10px;
          border-radius: 6px;
          height: 13px;
          background-color: #007bff33;
          border: 1px solid #007bff73;

          label {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .caution {
        display: flex;
        font-size: 12px;
        color: #666e80;
        margin-bottom: -22px;
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btnL {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .removeBtn {
          display: flex;
          /* height: 40px; */
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fa0000;
          box-shadow:
            0px 4px 8px 0px rgba(163, 59, 8, 0.08),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #fa0000;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }

        .reportBtn {
          color: #324056;
          text-align: center;

          /* Body / Bold */

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: none;
        }
      }

      .btnR {
        display: flex;
        gap: 5px;
        .sendBtn,
        .filterBtn {
          display: flex;
          /* height: 48px; */
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #1e293b;

          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
        .filterBtn {
          border: 1px solid $gray-500;
          background: $primary-white;
          color: #364968;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .transactionList {
    .transactionListHead {
      .transactionListHeadIn {
        .transactionListHeadD {
          h3 {
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }
        }
        .transactionListHeadBtn {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .addTransactionBtn {
            font-size: 12px;
          }
        }
      }

      .transactionListFilter {
        .transactionListSearch {
          height: 30px;
          width: auto;
        }
      }
    }

    .transactionListTable {
      tbody {
        tr {
          td {
            font-size: 13px;

            .tableWallet {
              .tableWalletDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableWalletEmail {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .filterModalOver {
    width: 90% !important;
  }
}

@media screen and (max-width: 550px) {
  .filterModalOver {
    height: 90%;
  }
}
