@import "../../variable/colors.scss";

@keyframes popIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.referralProgramContainer {
  .referralInvitation {
    position: absolute;
    right: 1.5rem;
    bottom: 1.8rem;
    background-color: #fb837a;
    border-radius: 50%;
    padding: 6px;
    transition: transform 0.3s ease;
    cursor: pointer;
    animation: popIn 0.3s forwards;
    // animation: pulse 1.5s infinite;
    z-index: 1;

    .pulseWave {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 3.8rem;
      height: 3.8rem;
      background-color: rgba(251, 131, 122, 0.5);
      border-radius: 50%;
      animation: pulseWave 1.5s infinite;
      z-index: -1;
    }

    @keyframes pulseWave {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.5);
        opacity: 0.5;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    &.closed {
      display: none;
    }

    &:hover {
      transform: scale(1.1);
    }

    .actionIcon {
      width: 30px;
      height: 30px;
      color: white;
      border: 2px white solid;
      border-radius: 50%;
      padding: 10px;
    }
  }

  .iframeSkeleton {
    display: flex;
    margin: 20px 50px;
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 #33415614;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto;

    .imageSkeleton {
      height: 400px;
      width: -webkit-fill-available;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0px 20px 20px;

      .textSkeleton {
        font-size: 2rem;
      }
    }
  }
}

.inivitationContainer {
  display: flex;
  flex-direction: column;
  margin: 13px;
  gap: 15px;
  width: 25vw;
  min-width: 215px;

  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    h1 {
      color: $primary-black;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    button {
      border: none;
      background-color: $primary-white;
      cursor: pointer;
    }
  }
  .referralTile {
    color: #333334;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    span {
      color: #5b5b5c;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  body {
    margin: 13px 10px 6px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 21rem;
    overflow: auto;
    padding-right: 15px;

    .inputField {
      // width: 25vw;

      .input {
        display: flex;
        gap: 10px;
        @include labelInput;
        input {
          width: 100%;
          flex: 9;
        }

        .mobileSelect {
          display: flex;
          width: 100%;
          gap: 15px;
        }
      }

      .removeBtn {
        border: 1.5px solid #dedada;
        display: flex;
        align-items: center;
        padding: 11px;
        border-radius: 5px;
        font-size: 20px;
        color: #a99b9b;
        flex: 1 1;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: rgb(192, 67, 67);
          border-color: rgb(192, 67, 67);
        }
      }

      .error {
        display: flex;
        color: rgb(254, 44, 44);
        gap: 4px;
        font-size: 12px;
        font-style: normal;
        line-height: 20.8px;
        align-items: center;

        .errIcon {
          width: 15px;
        }
      }
    }
  }

  .changeField {
    display: flex;
    justify-content: space-between;

    p,
    .inputType {
      color: $dark;
      font-size: 10px;
      font-weight: 700;
      font-style: normal;
      line-height: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .sendBtn {
    display: flex;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: $dark;
    font-weight: 600;
    line-height: 24px;
    text-transform: none;

    &:hover {
      background-color: $dark;
    }
  }
}

.modalShadow {
  background-color: rgba(255, 255, 255, 0);

  .modaluiOver {
    box-shadow:
      0px 2px 16px 20px rgba(0, 0, 0, 0.1),
      0px 1px 20px 8px rgba(0, 0, 0, 0.05);
    top: auto;
    bottom: 2%;
    left: auto;
    right: 2%;
    animation: popIn 0.3s forwards;
    &.closing {
      animation: popOut 0.3s forwards;
    }
  }
}
