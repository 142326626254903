@import "../../variable/colors.scss";
.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;
  margin-top: -20px;

  .errIcon {
    width: 15px;
  }
}
.message {
  display: flex;
  color: rgb(3, 146, 39);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;
  margin-top: -20px;

  .errIcon {
    width: 15px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 4px;
}
.username {
  @include labelInput;
  width: 50%;
  label {
  }

  input {
    border: 1px solid #e0e2e7;
  }
}
