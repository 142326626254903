@import "../../variable/colors.scss";

.wrapper {
  padding: 25px;

  .WelCard {
    display: flex;
    margin-bottom: 25px;
    height: 140px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: auto;
    padding: 16px 0px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $primary-white;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .listHead {
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      align-self: stretch;

      .listHeadIn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .listHeadD {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;

          .title {
            display: flex;
            gap: 5px;
            color: $dark;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 162.5%;
            letter-spacing: -0.8px;
          }

          p {
            color: $secondary;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }

        .listHeadBtn {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .createPlan {
            color: $primary-white;
            background: $dark;
            border: 1px solid $dark;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: none;
            display: flex;
            gap: 8px;
          }
        }
      }

      .cardListFilter {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .tableColumnContainer {
          width: auto;
        }
      }
    }

    .listTable {
      display: flex;
      width: 100%;
      overflow-x: auto;

      .tableIn {
        width: 100%;
        table {
          width: 100%;
          border: 1px solid $gray-300;

          border-collapse: collapse;

          thead {
            width: 689px;
            height: 40px;
            border: 1px solid $gray-300;
            background: $gray-100;
            background: $gray-100;

            tr th {
              color: #4b5563;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              text-align: left;
              padding: 0px 20px;
              background-color: #f9fafb;
            }
          }
        }

        tbody {
          tr {
            width: 689px;
            padding: 0px 20px;
            border: 1px solid var(--gray-400, #ced4da);

            .tableStatus {
              // display: flex;
              // width: 200px;
              padding: 8px 0px;
              gap: 8px;
              .green {
                display: flex;
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgb(49 92 28 / 64%);
                background: rgb(103 194 58 / 7%);
                width: 50px;
                height: 23px;
                color: #345f1e;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .grey {
                display: flex;
                align-items: center;
                border-radius: 6px;
                border: 1px solid rgb(100 116 139);
                background: rgba(100, 116, 139, 0.1);
                width: 63px;
                height: 23px;
                color: #303843;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
              .yellow {
                display: flex;
                /* padding: 4px 4px; */
                align-items: center;
                border-radius: 6px;
                border-radius: 6px;
                border: 1px solid rgba(100, 116, 139, 0.45);
                background: rgba(215, 156, 4, 0.37);
                width: 89px;
                height: 23px;

                /////text///////
                color: $secondary;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
                justify-content: center;
              }
            }

            td {
              width: 689px;
              padding: 9px 20px;

              text-align: left;
              color: $secondary;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
      }
    }
    .paginationWrapper {
      display: flex;
      justify-content: center;
    }
  }
}
