.cardContainer {
  background-color: #dcdada36;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 18%);
  padding: 2rem;
  width: 340px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  .bookmark {
    position: absolute;
    top: -10px;
    right: 10px;
    padding: 0.5rem 1rem;
    border-radius: 4px 4px 0 0;
    color: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    height: 25px;
    transition:
      top 0.3s ease,
      background-color 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 71%, 0 100%, 0 0);

    span {
      display: inline-block;
      transform: translateX(0);
    }
  }

  .planTitle {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .planPrice {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
  }

  .planDescription {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    hr {
      height: 2px;
      background-color: #222;
    }

    ol {
      padding-left: 20px;
      position: relative;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        text-align: left;
        gap: 10px;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      position: relative;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        text-align: left;
        gap: 10px;

        &::before {
          align-items: center;
          background-color: #00a651;
          border-radius: 50%;
          color: #fff;
          content: "✓";
          display: inline-block;
          display: flex;
          font-size: 0.8rem;
          font-weight: 700;
          justify-content: center;

          padding: 3px 4px;
        }
      }
    }
  }

  .subsBtn {
    display: flex;
    width: 100%;
    height: -webkit-fill-available;
    align-items: flex-end;

    .demoButton {
      margin-top: 2rem;
      background-color: #0a0e1a;
      color: #ffffff;
      font-weight: bold;
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      width: 100%;
      height: 45px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #1f283e;
      }
    }
  }
}
