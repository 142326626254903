@import "src/variable/colors.scss";

.mismatchBtn {
  background-color: transparent;
  color: #1c58bc;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  text-decoration: underline;

  cursor: pointer;
}

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .detailContainer {
        display: flex;
        gap: 20px;
        .userInfo,
        .walletInfo {
          display: flex;
          flex-direction: column;
          width: 25vw;
          gap: 15px;

          @include labelInput;

          .userdata,
          .walletdata {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            gap: 10px;

            .item {
              display: flex;
              gap: 8px;
              justify-content: space-between;
              align-items: flex-start;

              label {
                white-space: nowrap;
              }

              .value {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .loader {
        display: flex;
        gap: 10px;
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}
