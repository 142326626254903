@import "src/variable/colors.scss";

.walletConnect {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid $gray-300;
  background: $primary-white;
  height: 300px;

  .walletHead {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .headL {
      color: $dark;

      /* Large bold */
      //   font-family: Noto Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
    }
    .headR {
      display: flex;
      // padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid $gray-300;
      background: $primary-white;

      /* Shadow cards */
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      cursor: pointer;

      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
      }

      &:hover {
        // background: rgba(28, 52, 90, 0.12);
        border-radius: 6px;
        -webkit-backdrop-filter: blur(3.5px);
        backdrop-filter: blur(3.5px);
        box-shadow: 2px 2px 6px 3px rgba(182, 182, 182, 0.217);
        transform: translateY(-0.1ch);
      }

      &:active {
        animation: gradient 50ms;
        background: rgba(52, 70, 100, 0.325);
      }
    }
  }
  .walletInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;

    .walletMember {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      width: 100%;
      max-height: 255px;
      overflow-y: auto;
      // justify-content: center;

      p {
        border: 2px dashed #b7b7b7;
        padding: 25px;
        border-radius: 10px;
        display: flex;
        margin-top: 10%;
        color: #6f727a;
        margin: 4.55em 3rem;
      }

      // a {
      //   display: contents;
      // }
      .memberList {
        display: flex;
        align-items: center;
        gap: 2px;
        align-self: stretch;
        width: 100%;
      }
    }

    @include scrollbarDesign2;
  }
}
