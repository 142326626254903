@import "src/variable/colors.scss";

.formModal {
  display: flex;

  .modalContent {
    width: 45vw;
    background-color: white;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;

    .successModalTitle {
      width: 100%;
      color: $dark;
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: center;
    }
    .modalPara {
      text-align: center;
      color: $dark;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 15px;
    }

    .successButtonsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;

      .nextSuccessButton {
        width: 168px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #1e293b;
        color: white;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .skipSuccessButton {
        width: 168px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 12px;
        background: white;
        color: black;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }
}
