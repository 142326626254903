@import "src/variable/colors.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.referModalBox {
  background: white;
  border-radius: 12px;
  width: 700px;
  max-width: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  text-align: center;

  .referModalTop {
    margin: 24px 40px;

    .close {
      display: flex;
      justify-content: center;
      align-items: center;

      h5 {
        color: $primary-black;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
    }

    .description {
      font-size: 15px;
      margin-top: 20px;
    }

    .infoText {
      font-size: 15px;
      color: #333;
      font-weight: bold;
      margin-top: 20px;
    }

    .addressBox {
      background: $secondary-gray-1;
      padding: 16px;
      border-radius: 8px;
      margin-top: 25px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 11px;

      p {
        display: flex;
        width: 80%;
        font-size: 14px;
        font-weight: 400;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
      }

      strong {
        flex: 1;
        text-align: left;
        color: $primary-black;
      }

      span {
        flex: 2;
        text-align: left;
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      gap: 330px;
    }

    .changeAddressButton {
      color: $dark;
      background: $primary-white;
      border: 1px solid $dark;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: none;
      border-radius: 8px;
    }

    .confirmButton {
      border-radius: 8px;
      font-weight: 600;
      text-transform: none;
      padding: 10px;
      background: #1e293b;
      color: white;
    }
  }
}
