@import "../../../variable/colors.scss";

.modalTop {
  //background: $primary-white;
  padding: 25px 40px;
  border-radius: 12px 12px 0 0;
  //width: 1100px;
  max-width: 800px;
  overflow: hidden;

  .close {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-size: 20px;
      font-weight: 700;
      color: $primary-black;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }
}

/*
.planDescription {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  .greenTickIcon {
    color: #28a745;
    margin-right: 8px;
  }
}

.subscriptionContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 26px;
  margin-top: 16px;
}
*/

.rootContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);

  .modalTop {
    margin: 25px 40px 15px;
    margin-right: 15px;
    //max-height: 500px;
    overflow: hidden;
    overflow-y: hidden;
    //width: 1100px;
    text-align: center;
  }
}

.pricingCard {
  background-color: white;
  margin-top: 30px;
}

.carouselWrapper {
  padding-top: 20px;
  overflow: visible;
}

.rootContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.carouselWrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.cardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.pricingCard {
  width: 100%;
  max-width: 340px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .rootContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    width: 60%;
    max-width: 800px;
    margin: auto;
    /* background-color: white; */
    /* left: 750px; */
  }
}
