@import "src/variable/colors.scss";

@mixin label {
  color: $dark;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.8px; /* 160% */
}

@mixin input {
  display: flex;
  padding: 11px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid $gray-400;
  background: $primary-white;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  h1 {
    color: $dark;

    /* H1 */
    // font-family: Noto Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 50px */
    letter-spacing: -0.8px;
  }
}
.formInput {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  gap: 16px;
  align-self: stretch;

  .email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    label {
      @include label;
    }

    input {
      @include input;
    }
  }

  .password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    label {
      @include label;
      border: none;
    }

    input {
      @include input;
      border: none;
    }
  }

  .resetPass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    label {
      @include label;
    }

    input {
      @include input;
      border: none;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkboxL {
      display: flex;
      align-items: center;
      gap: 4px;
      input {
        width: 24px;
        height: 20px;
      }

      label {
        color: $dark;
        font-feature-settings:
          "clig" off,
          "liga" off;

        /* Xs */
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .checkboxR {
      a {
        color: black;
      }
    }
  }
}
.signIn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  .SignInBtn {
    display: flex;
    width: 356px;
    height: 40px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: 6px;
    background: $dark;
  }
}

.overlayContainerR {
  display: flex;
  .overlayR {
    .overlayRight {
      span {
        color: $dark;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        b {
          cursor: pointer;
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 15px;
  font-style: normal;
  // font-weight: 600;
  line-height: 20.8px;
}
