@import "src/variable/colors.scss";

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      width: 60dvw;
      gap: 16px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .subscriptionList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        max-height: 40rem;
        overflow: auto;
        padding: 10px;
        justify-content: space-around;

        .pricingPlanContainer {
          width: 17rem;
          padding: 1rem;
        }
        .pricingPlanTitle {
          font-size: 1.3rem;
        }
        .pricingPlanSubTitle {
          font-size: 2rem;
        }
        .selectedButton {
          background-color: #097109;
        }
      }

      .noSubs {
        display: flex;
        height: 23rem;
        align-items: center;
        justify-content: center;

        p {
          display: flex;
          padding: 48px;
          height: 5rem;
          width: 15rem;
          border-radius: 12px;
          border: 2px dashed #b4b6b9;
          align-items: center;
          justify-content: center;
          color: #88898d;
          font-size: 18px;
          font-weight: 600;
          line-height: 150%;
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}
