@import "src/variable/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 22rem;
  width: 100%;
  margin-right: 10px;
  gap: 10px;
  .card {
    display: flex;
    flex-direction: column;
    min-height: 60px;
    height: fit-content;
    gap: 20px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    background: rgba(217, 217, 217, 0.3);
    justify-content: space-between;

    .head {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .status {
        color: #34a853;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .date {
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .uploadphoto {
    // padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    .addReceipt,
    .addPhoto {
      display: flex;
      width: 100%;
      height: 50px;
      gap: 6px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.6);
      color: #000;
      background: rgba(217, 217, 217, 0.3);

      .logo {
        display: flex;
      }
      .text {
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        text-transform: none;
      }
    }
  }
}
