@import "src/variable/colors.scss";

.internalPaymentCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  margin: 33px 15px 15px 25px;

  .fromWallet,
  .toWallet {
    display: flex;
    flex-direction: column;
    gap: 22px;
    .error {
      display: flex;
      color: rgb(254, 44, 44);
      gap: 4px;
      font-size: 12px;
      font-style: normal;
      line-height: 20.8px;
      align-items: center;
      margin-top: -17px;

      .errIcon {
        width: 15px;
      }
    }
    .success {
      display: flex;
      color: rgb(18, 121, 12);
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20.8px;
      align-items: center;
      margin-top: -17px;

      // margin-top: -28px;
      // margin: 5px 0px 0px 30px;
    }
  }

  .amountCard {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2980392157);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2509803922);
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 158px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 35px 12px;
    padding-left: 14px;

    .title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: normal;
      text-align: center;
    }
    .amount {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .amtValue {
        display: flex;
        align-items: center;
        background-color: #d9d9d9;
        border: 1px solid rgba(0, 0, 0, 0.2980392157);
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2509803922);
        gap: 10px;
        height: 25px;
        justify-content: center;
        padding: 2px 10px;
        width: 37px;
        height: 30px;
      }
    }
    .amountInput {
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2980392157);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2509803922);
        border-radius: 10px;
        padding: 11px 25px;
        gap: 10px;
        height: 24px;
        width: 212px;
      }
    }
  }
  .error {
    display: flex;
    color: rgb(254, 44, 44);
    gap: 4px;
    font-size: 12px;
    font-style: normal;
    line-height: 20.8px;
    align-items: center;
    .errIcon {
      width: 15px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    // margin: 0px 10px;
    gap: 20px;
    .backBtn,
    .continueBtn {
      font-size: 16px;
      font-weight: 700px;
      height: 56px;
      width: 100%;
      background-color: $dark;
      border-radius: 10px;
    }
    .backBtn {
      background-color: #d9d9d9;
      color: #000;
    }
  }
}

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .setting {
        display: flex;
        // width: 675px;
        padding: 0px 31px 0px 13px;
        align-items: flex-start;
        gap: 10px;
        flex-direction: column;
        text-align: left;
        color: rgb(63, 63, 63);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btnL {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .removeBtn {
          display: flex;
          /* height: 40px; */
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fa0000;
          box-shadow:
            0px 4px 8px 0px rgba(163, 59, 8, 0.08),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #fa0000;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }

        .reportBtn {
          color: #324056;
          text-align: center;

          /* Body / Bold */

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: none;
        }
      }

      .btnR {
        .sendBtn {
          display: flex;
          /* height: 48px; */
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #1e293b;

          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
      }
    }

    .progress {
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;
    }
  }
}
