@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.Dashboard {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .welcomeCard {
    width: 100%;
    height: 133px;
  }

  .topWallet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: auto;
    margin: 15px;
    margin-right: 0px;

    .topWalletHead {
      display: flex;
      gap: 8px;
      flex-direction: column;
      width: 100%;

      .topWalletHeadIn {
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 10px;

        .topWalletHeadL {
          display: flex;
          gap: 20px;
          align-items: center;

          h1 {
            color: $dark;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 137%;
            letter-spacing: -0.8px;
          }

          .walletSearch {
            display: flex;
            height: 38px;
          }
        }

        .topWalletHeadR {
          display: flex;

          button {
            text-transform: none;
          }
        }
      }

      .walletCards {
        display: flex;
        /* width: 1260px; */
        align-items: flex-end;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
      }
    }

    .orgInsigts {
      display: flex;
      gap: 8px;
      flex-direction: column;
      width: 100%;

      h1 {
        color: $dark;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 137%;
        letter-spacing: -0.8px;
        width: 98%;
        display: flex;
        margin: 0px 10px;
      }

      .transCard {
        display: flex;
        align-items: flex-end;
        width: 99%;
        flex-direction: row;
        flex-wrap: wrap;

        .transCardContainer,
        .latesttransCardContainer {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: auto;
          height: 130px;
          padding: 11px 16px;
          border-radius: 12px;
          border: 1px solid #dde0e5;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
          margin: 10px;
          align-items: center;
          min-width: 230px;
          margin: 10px;
          justify-content: space-around;

          .head {
            display: flex;
            width: 100%;
            align-items: center;

            h3,
            .cardTitle {
              color: #1e293b;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }

            .cardTitle {
              display: flex;
              width: 100%;
            }

            .date {
              display: flex;
              gap: 4px;
              text-align: left;
              color: $secondary-gray-4;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }

            h2 {
              color: #1e293b;
              font-style: normal;
              font-size: 40px;
            }
          }

          .card {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .cardHolder {
              display: flex;
              align-items: center;
              gap: 12px;

              img {
                width: 32px;
                height: 32px;
                flex-shrink: 0;
                border-radius: 50%;
              }

              .cardHolderName {
                display: flex;
                padding: 0px 12px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                h3,
                h2,
                h1 {
                  display: flex;
                  flex-direction: column;
                  align-self: stretch;
                  color: $dark;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 162.5%;
                  letter-spacing: -0.8px;
                  text-align: left;
                }

                p {
                  display: flex;
                  flex-direction: column;
                  align-self: stretch;

                  color: $secondary;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  text-align: left;
                }
              }
            }

            .cardBalance {
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 100%;

              .amount,
              .balance {
                display: flex;
                gap: 10px;

                .heading {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  color: $secondary;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 150%;
                  text-align: left;
                }
                .value {
                  display: flex;
                  width: auto;
                  flex-direction: column;
                  justify-content: center;
                  color: $dark;
                  font-size: 17px;
                  font-weight: 600;
                  line-height: 137%;
                  letter-spacing: -0.8px;
                  padding-right: 12px;
                  text-align: left;
                }
              }
            }
          }
        }

        .transCardContainer {
          justify-content: center;
        }

        .latesttransCardContainer {
          gap: 6px;
        }
      }
    }
  }

  .DashTrans {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px;

    .recentTransOver {
      max-width: 100%;
    }
  }

  // }
}

@media screen and (max-width: 800px) {
  .Dashboard {
    .topWallet {
      .topWalletHead {
        .topWalletHeadIn {
          .topWalletHeadL {
            flex-direction: column;
            align-items: flex-start;
            h1 {
              font-size: 20px;
            }
          }
          .topWalletHeadR {
            margin-right: 10px;
          }
        }
        .walletCards {
          display: flex;
          align-items: flex-end;
          // flex-wrap: wrap;
          flex-wrap: nowrap;
          flex-direction: row;
          width: 100%;
          overflow-x: auto;
        }
      }
      .orgInsigts {
        h1 {
          font-size: 20px;
        }
      }
    }

    .DashTrans {
      .recentTransOver {
        max-width: 100%;
      }
    }
  }
}
