@import "../../variable/colors.scss";
* {
  margin: 0;
  padding: 0;
}

.ledger {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .ledgerWelCard {
    display: flex;
    margin: 15px;
    margin-top: 33px;
    height: 140px;
  }

  .ledgerTableCard {
    display: flex;
    flex-direction: column;
    gap: 16px;
    // width: 100%;
    margin: 15px;

    height: auto;
    padding: 16px 0px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $primary-white;
    /* Shadow cards */
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);

    .ledgerListHead {
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      align-self: stretch;

      h3 {
        color: $dark;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 162.5%;
        letter-spacing: -0.8px;
      }

      p {
        color: $secondary;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .ledgerListTable {
      display: flex;
      width: 100%;
      overflow-x: auto;

      .tableIn {
        width: 100%;
        table {
          width: 100%;
          border: 1px solid $gray-300;

          border-collapse: collapse;

          thead {
            // display: flex;
            width: 689px;
            height: 40px;
            border: 1px solid $gray-300;
            background: $gray-100;
            background: $gray-100;

            tr th {
              color: #4b5563;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              text-align: left;
              padding: 0px 20px;
              background-color: #f9fafb;
            }
          }
        }

        tbody {
          tr {
            width: 689px;
            padding: 0px 16px;
            border: 1px solid $gray-400;

            td {
              width: 689px;
              padding: 9px 20px;

              text-align: left;
              color: $secondary;

              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;

              .operation {
                cursor: pointer;
              }

              .tableDate {
                display: flex;
                // width: 177px;
                flex: 1 0 0;
                gap: 4px;
                text-align: left;

                // color: #17181a;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 18px */
              }

              .tableMerchent,
              .tableWallet,
              .activityStatus,
              .tableAmount,
              .activityname,
              .tableBalance {
                // color: $dark;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 162.5%;
                text-align: left;
              }

              .activityname {
                color: $dark;
              }

              .tableBalance {
                color: green;
              }
            }
          }
        }
      }
    }

    .paginationWrapper {
      display: flex;
      justify-content: center;
      // margin-top: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .ledgerList {
    .ledgerListHead {
      h3 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }

    .ledgerListTable {
      tbody {
        tr {
          td {
            font-size: 13px;

            .tableWallet {
              .tableWalletDetail {
                .tableMemberName {
                  font-size: 13px;
                }
                .tableWalletEmail {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
