.timeBody {
  display: flex;
}

.sideBar {
  width: 268px;
  background-color: #0f172a;
  /* height: 100vh; */
  margin-top: 40px;
}

.mainContent {
  flex-grow: 1;
  background: linear-gradient(#704dd8, #e36094, #d23131);
  height: 100vh;
}

.thermometer {
  width: 20px;
  height: 100%;
  background-color: #eee;
  position: relative;
}

.thermometerSection {
  width: 3.5px;
  height: 7rem;
  background-color: #eee;
  margin-bottom: 4px;
  position: relative;
  margin-left: 150px;
}

.thermometerSection:last-child::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
}

.thermometerFill {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #0e91ef;
  transition: height 0.3s ease;
}
.sectionLabel,
.sectionLabelActive {
  display: block;
  position: absolute;
  right: 415%;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: whitesmoke;
  white-space: nowrap;
  margin-top: -56px;
}

.sectionLabelActive {
  color: #78fbd7;
}
.subsectionLabelContainer {
  position: absolute;
  left: 120%;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.subsectionLabel {
  color: #78fbd7;
  margin-left: 10px;
  font-size: 12px;
}

.lastSectionLabel {
  color: white;

  white-space: nowrap;
  left: 50%;
  transform: translateX(-130%);
  margin-left: -47px;
  margin-top: -10px;
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  transform: translateX(-50%);
  bottom: -15px;
}

.nodeCircle {
  content: "";
  width: 12px;
  height: 12px;
  background-color: white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  top: -6px;
  background-color: white;
}

.nodeCircle.active {
  background-color: #0e91ef;
}

@media (max-width: 600px) {
  .sideBar {
    /* width: 268px; */
    background-color: #0f172a;
    height: 100vh;
    margin-top: 62px;
  }

  .thermometerSection {
    width: 2.5px;
    height: 85px;
    background-color: #eee;
    margin-bottom: 4px;
    position: relative;
    margin-left: 15px;
  }
}
