.visaCard {
  padding: 10px;
  perspective: 1000px;
  width: 320px;
  height: 220px;

  .cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;

    &.flipped {
      transform: rotateY(180deg);
    }

    .cardFront,
    .cardBack {
      position: absolute;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .cardFront {
      z-index: 2;
    }

    .cardBack {
      height: 215px;
      width: 320px;
      transform: rotateY(180deg);
      background-color: rgb(255 255 254);
      border: 1px solid rgb(210 93 90);
      border-radius: 16px;

      .cardBackTop {
        height: 20px;
        display: flex;
        font-size: 9px;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
      }

      .blacklStrap {
        width: 100%;
        height: 40px;
        background-color: black;
      }

      .cardBackBottom {
        .instruction {
          display: flex;
          font-size: 6px;
          align-items: flex-end;
          padding-right: 10px;

          .instruction1 {
            width: 180px;
          }
          .instruction2 {
            width: 97px;
            font-size: 4px;
            height: 100%;
            text-align: end;
          }
        }

        .cardInfo {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          margin-left: 20px;
          gap: 4px;
          .cardNumber,
          .cardHolderName,
          .validitiy {
            font-size: 15px;
            color: rgb(167 105 83);
          }

          .validitiy {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .validFrom,
            .validTo {
              display: flex;

              label {
                font-size: 8px;
                width: 31px;
              }
            }
          }
        }
      }
    }
  }
}
