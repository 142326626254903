@import "src/variable/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 33px 15px 15px 25px;
  background: $gray-100;
  gap: 25px;
  width: 260px;

  .otpInputContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .pinInput {
      display: flex;
      flex-direction: column;
      gap: 5px;
      label {
        font-size: 14px;
        font-weight: 500;
      }

      input {
        border: 2px solid #97979a !important;
        border-radius: 8px;
        padding: 10px;
        margin: 5px;

        &:focus {
          border: 2px solid #3498db !important;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    // margin: 0px 10px;
    gap: 20px;
    .backBtn,
    .continueBtn {
      font-size: 16px;
      font-weight: 700px;
      height: 56px;
      width: 100%;
      background-color: $dark;
      border-radius: 10px;
    }
    .backBtn {
      background-color: #d9d9d9;
      color: #000;
    }
  }
}
