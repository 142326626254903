/* StackedCarousel.module.css */
.stackedCarousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin: auto;
  padding: 0;
  touch-action: pan-y;
}

.stackedCarouselContainer {
  position: relative;
  width: 100%;
  max-width: 27rem;
  height: 380px;
  /* margin: 20px; */
  overflow: hidden;
  padding: 0px 18px;
}

.cardSummary {
  width: 100%;
  height: 100%;
  // box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 0;
}

.card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  transform: scale(1) translateX(40px);
}

.active {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 3;
  padding: 5px;
}

.next {
  opacity: 0.6;
  z-index: 2;
  transform: scale(0.9) translateX(40px);
}

.prev {
  transform: scale(0.9) translateX(-40px);
  z-index: 1;
  opacity: 0.6;
}

.inactive {
  transform: scale(1) translateX(0);
  z-index: 0;
  opacity: 0;
}

.stackedCarouselButton {
  cursor: pointer;
  border: none;
  padding: 4px;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: #eae8e87d;
  }
}

@media screen and (max-width: 440px) {
  .stackedCarouselButton {
    display: none;
  }
  .next {
    transform: scale(0.8) translateX(61px) translateY(-16px);
  }

  .prev {
    transform: scale(0.8) translateX(-61px) ranslateY(-16px);
  }
}
