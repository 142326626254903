@import "../../variable/colors.scss";

.depositwrappper {
  padding: 25px;

  .WelCard {
    display: flex;

    margin-bottom: 25px;
    height: 140px;
  }

  .depositContainer {
    display: flex;
    gap: 10px;
    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-left: 15px;
      max-width: 300px;

      .amountCard {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #0000004c;
        border-radius: 10px;
        box-shadow: 0px 4px 4px #00000040;
        display: flex;
        flex-direction: column;
        gap: 18px;
        height: 158px;
        justify-content: center;
        overflow: hidden;
        padding: 0px 35px 2px;

        .title {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: normal;
          text-align: center;
        }
        .amount {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .amtValue {
            display: flex;
            align-items: center;
            background-color: #d9d9d9;
            border: 1px solid #0000004c;
            border-radius: 10px;
            box-shadow: 0px 4px 4px #00000040;
            gap: 10px;
            height: 25px;
            justify-content: center;
            padding: 2px 10px;
          }
        }
        .amountInput {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            border: 1px solid #0000004c;
            box-shadow: 0px 4px 4px #00000040;
            border-radius: 10px;
            padding: 11px 25px;
            gap: 10px;
          }
        }
      }

      .btn {
        display: flex;
        gap: 10px;
        justify-content: space-around;
        margin: 0px 5px;

        .revokeBtn,
        .depositBtn {
          padding: 12px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: $dark;
          font-weight: 600;
          line-height: 24px;
          text-transform: none;
          width: 100%;
        }

        .depositBtn {
          background: $dark;
        }
      }
    }

    .detailContainer {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      margin: 0px 0px 19px 12px;
      box-shadow: 0px 4px 4px #00000040;
      flex: 1 0 0;
      border-radius: 12px;
      border: 1px solid $gray-300;
      background: $primary-white;
      max-width: 400px;
      width: fit-content;

      .userInfo,
      .walletInfo {
        display: flex;
        flex-direction: column;
        gap: 3px;

        @include labelInput;

        .userdata,
        .walletdata {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          gap: 3px;

          .label {
            display: flex;
            gap: 8px;
          }

          .value {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.actionTypeBtnGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.selectedAction {
  background-color: #1976d2;
  color: #fff;
}

.actionTypeBtnGroup button {
  flex-grow: 1;
}

@media screen and (max-width: 700px) {
  .depositContainer {
    display: flex;
    flex-direction: column;
  }
}
