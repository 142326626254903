@import "../../../variable/colors.scss";

.modalContainer {
  display: flex;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  max-width: 670px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .optionContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45%;
      padding: 16px;
      border-radius: 12px;
      text-align: center;

      .optionImage {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
        svg {
          width: 100%;
          height: 100%;
        }
      }

      h6 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
        color: $primary-black;

        strong {
          font-weight: 700;
        }
      }

      .optionButton {
        width: 170px;
        background: rgb(209, 208, 208);
        border-radius: 8px;
        padding: 12px;
        text-transform: none;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #232325;
        margin-top: 8px;
        white-space: nowrap;
        height: 45px;
      }
    }

    .divider {
      width: 2px;
      background: $secondary-gray-4;
      margin: 0 16px;
    }
  }

  .budgetOptions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 16px;
    width: 100%;

    .budgetOption {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

@media screen and (max-width: 900px) {
  .modalContainer {
    padding: 16px;
    max-width: 80%;
  }

  .modalHeader h5 {
    font-size: 20px;
  }

  .optionContainer {
    .option {
      width: 48%;
      padding: 12px;
      .optionButton {
        width: 100%;
      }
    }

    .divider {
      width: 1px;
      margin: 0 8px;
    }
  }
}

.budgetButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;

  .optionBackButton {
    background-color: lightgray;
    color: black;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    transition: all 0.3s ease;

    &:hover {
      background-color: $gray-500;
      color: black;
    }
  }

  .optionCreateButton {
    background-color: $primary-black;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: black;
    }

    &:disabled {
      background-color: #d1d5db;
      color: #6b7280;
    }
  }
}

@media screen and (max-width: 600px) {
  .modalContainer {
    display: flex;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    width: 100%;
    max-width: 670px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .optionContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45%;
        padding: 16px;
        border-radius: 12px;
        text-align: center;

        .optionImage {
          width: 64px;
          height: 64px;
          margin-bottom: 16px;
          svg {
            width: 100%;
            height: 100%;
          }
        }

        h6 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 12px;
          color: $primary-black;

          strong {
            font-weight: 700;
          }
        }

        .optionButton {
          width: 170px;
          background: rgb(209, 208, 208);
          border-radius: 8px;
          padding: 12px;
          text-transform: none;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 12px;
          color: #232325;
          margin-top: 8px;
          white-space: nowrap;
          height: 45px;
        }
      }

      .divider {
        width: 2px;
        background: $secondary-gray-4;
        margin: 0 16px;
      }
    }

    .budgetOptions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      padding: 16px;
      width: 100%;

      .budgetOption {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .modalContainer {
      padding: 16px;
      max-width: 80%;
    }

    .modalHeader h5 {
      font-size: 20px;
    }

    .optionContainer {
      .option {
        width: 48%;
        padding: 12px;
        .optionButton {
          width: 100%;
        }
      }

      .divider {
        width: 1px;
        margin: 0 8px;
      }
    }
    .budgetOptions {
      padding: 0px;
    }
  }

  @media screen and (max-width: 600px) {
    .modalContainer {
      padding: 12px;
      max-width: 90%;
    }

    .modalHeader h5 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }

    .optionContainer {
      .option {
        width: 48%;
        padding: 8px;
        .optionButton {
          font-size: 14px;
          padding: 10px;
        }
      }

      .divider {
        width: 1px;
        margin: 0 4px;
      }
    }

    .budgetOptions {
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      padding: 0px;
    }

    .budgetButtons {
      .optionBackButton,
      .optionCreateButton {
        font-size: 14px;
        padding: 10px;
      }
    }
  }

  .modalContainer {
    padding: 12px;
    max-width: 90%;
  }

  .modalHeader h5 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .optionContainer {
    .option {
      width: 48%;
      padding: 8px;
      .optionButton {
        font-size: 14px;
        padding: 10px;
      }
    }

    .divider {
      width: 1px;
      margin: 0 4px;
    }
  }

  .budgetOptions {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 0px;
  }

  .budgetButtons {
    .optionBackButton,
    .optionCreateButton {
      font-size: 14px;
      padding: 10px;
    }
  }
}
