@import "src/variable/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 33px 15px 15px 25px;
  gap: 40px;
  background: $gray-100;

  .walletContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // justify-content: center;
    .selectContainer {
      display: flex;
      //   flex-direction: column;

      gap: 10px;
      .participant,
      .wallet {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 305px;

        label {
          color: #000000cc;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: normal;
        }

        .select {
          color: #000000cc;
          font-size: 14px;
          font-weight: 500;
          height: 56px;
          letter-spacing: 0;
          line-height: normal;
          width: 95%;
          max-width: 300px;
        }
      }
    }
    .amountCard {
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #0000004c;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      // overflow: hidden;
      padding: 7px 50px;
      width: 296px;
      gap: 10px;
      .title {
        color: #000000;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        text-align: center;
      }
      .amount {
        color: #34a853;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        text-align: center;
      }
    }
  }
  .lastTransaction {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .transaction {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      .transCard {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #ffffff;
        border: 1px solid #0000004c;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 16px;
        width: 365px;
        .payment,
        .paymentdata {
          display: flex;
          justify-content: space-between;
          .amount,
          .description {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            line-height: normal;
            color: #cd2626;
          }

          .type,
          .participant {
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            line-height: normal;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    .walletContainer {
      .selectContainer {
        align-items: center;
        flex-direction: column;
      }
      .amountCard {
        width: 60%;
      }
    }
    .lastTransaction {
      .transaction {
        .transCard {
          width: 85%;

          .payment,
          .paymentdata {
            .description,
            .amount {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
