@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.userPage {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .userPageWelCard {
    display: flex;
    margin-top: 33px;
    background: url("../../assets/images/pinkBg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .welCardLOver {
      height: 75px !important;
      width: 75px !important;
    }
  }
  .userPageHead {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 30px;
    margin: 24px;

    .walletMemberOver {
      min-height: 16rem;
    }

    .cashCard {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: auto;
      height: auto;
      padding: 24px 16px;
      border-radius: 12px;
      border: 1px solid #dde0e5;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      flex: 1 1;
      justify-content: space-between;

      .cashCardHeader {
        display: flex;
        justify-content: space-between;
        gap: 25px;

        h3 {
          color: #1e293b;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        .cashDeposit {
          display: flex;
          gap: 7px;
          height: 35px;
          background: $dark;
          border: radius 6px;
          align-items: center;

          color: $primary-white;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }

      .cashCardMain {
        display: flex;
        gap: 18px;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;

        h3 {
          color: #1e293b;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        h2 {
          color: #1e293b;
          font-style: normal;
          font-size: 40px;
        }
      }

      .noData {
        display: flex;
        justify-content: space-around;
        p {
          border: 2px dashed #b7b7b7;
          padding: 25px;
          border-radius: 10px;
          display: flex;
          margin-top: 10%;
          color: #878787;
          margin: 4.55em 3rem;
        }
      }
    }

    //  .editIcon {
    //    width: 14px;
    //    height: 14px;
    //   color: #64748b;
    // }
  }
  .userPageMid {
    display: flex;
    align-items: stretch;
    margin: 17px;
    justify-content: space-between;
    margin-top: 0;
    height: auto;
    gap: 15px;

    .recentTransOver {
      max-width: 60vw;
    }

    .transCards {
      display: flex;
      flex-direction: column;
      width: 100%;

      h1 {
        color: $dark;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 137%;
        letter-spacing: -0.8px;
        width: 98%;
        display: flex;
        margin: 6px 10px;
      }

      .transCardContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: auto;
        height: auto;
        padding: 24px 16px;
        border-radius: 12px;
        border: 1px solid #dde0e5;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
        margin: 10px;
        flex: 1 1;
        justify-content: space-between;
        align-items: center;

        h3 {
          color: #1e293b;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        h2 {
          color: #1e293b;
          font-style: normal;
          font-size: 40px;
        }
      }
    }
  }
  .userPageBottom {
    display: flex;
    align-items: stretch;
    margin: 17px;
    justify-content: space-between;
    margin-top: 0;
    height: auto;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: row;

    .cardListContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: auto;
      height: auto;
      padding: 24px 16px;
      border-radius: 12px;
      border: 1px solid $gray-300;
      background: $primary-white;
      /* Shadow cards */
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      flex: 2 1;
      margin: 10px;
      .cardListTable {
        display: flex;
        height: auto;
        padding: 16px 0px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 12px;
        border: 1px solid $gray-200;
        background: $primary-white;

        /* Shadow cards */

        .cardListHead {
          display: flex;
          width: 95%;
          margin: 0px 16px;
          justify-content: space-between;
          gap: 16px;

          .cardListHeadD {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
              color: #1e293b;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }

            p {
              color: #64748b;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
          .orderCardBtn {
            display: flex;
            gap: 7px;
            height: 35px;
            background: $dark;
            border: radius 6px;
            align-items: center;

            color: $primary-white;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: none;
          }
        }

        .tableIn {
          width: -webkit-fill-available;
          overflow-x: auto;

          table {
            border-collapse: collapse;
            width: 100%;

            thead {
              height: 40px;
              border: 1px solid $gray-300;
              background: $gray-100;
              border-left: none;
              border-right: none;
              background: $gray-100;

              tr th {
                color: #4b5563;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                text-align: left;
                padding: 0px 20px;
                background-color: #f9fafb;
              }
            }

            tbody {
              tr {
                padding: 0px 16px;
                border: none;
                border-top: 1px solid $gray-400;
                td {
                  padding: 9px 20px;

                  text-align: left;
                  color: $secondary;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }
              }
            }
          }
        }

        .noData {
          display: flex;
          width: 100%;
          justify-content: center;
          p {
            border: 2px dashed #b7b7b7;
            padding: 25px;
            border-radius: 10px;
            display: flex;
            margin-top: 10%;
            color: #878787;
            margin: 4.55em 3rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 886px) {
  .userPage {
    .userPageMid {
      display: flex;
      flex-wrap: wrap;
      height: auto !important;

      .recentTransOver {
        max-width: 100vw;
        width: 100%;
      }

      .transCards {
        display: flex;
        flex-direction: row !important;
        padding: 0px !important;
        flex-wrap: wrap;
        height: auto !important;
      }
    }

    .userPageBottom {
      .cardListContainer {
        .cardListTable {
          .cardListHead {
            .cardListHeadD {
              h3 {
                font-size: 12px;
                font-weight: 500;
              }
              p {
                font-size: 10px;
                font-weight: 400;
              }
            }

            .orderCardBtn {
              font-size: 12px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
