@import "src/variable/colors.scss";

.referModalBox {
  display: flex;
  padding: 16px;
  flex-direction: column;
  // align-items: center;
  gap: 24px;
  flex: 1 0;
  border-radius: 12px;
  border: 1px solid #dde0e5;
  background: #fff;

  .referModalTop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .heading {
      color: #1e293b;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    .closeBtn {
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid #dde0e5;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
      cursor: pointer;

      .editIcon {
        width: 14px;
        height: 14px;
        color: #64748b;
      }
    }

    .saveBtn {
      text-transform: none;
      background-color: $dark;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }

  .skeleton {
    margin: 20px 55px;
    width: 30vw;
    border-radius: 8px;
  }

  .referModalBottom {
    display: flex;
    flex-direction: column;
    // margin: 20px 30px;
    gap: 24px;
    // height: 30vh;
    overflow-y: auto;

    // .actionButton {
    //   display: flex;
    //   gap: 12px;
    //   justify-content: flex-end;
    //   .selectAllBtn,
    //   .deselectAllBtn {
    //     display: flex;
    //     padding: 3px 10px;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 10px;
    //     border-radius: 8px;
    //     background: $dark;

    //     // font-weight: 600;
    //     line-height: 24px;
    //     text-transform: none;
    //   }
    // }

    .merchant {
      display: flex;
      border: 1px solid $secondary-gray-2;
      border-radius: 12px;
      padding: 12px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
      justify-content: space-between;
      align-items: center;

      .merchantItem {
        display: flex;
        align-items: center;
        gap: 12px;

        .merchantInfo {
          display: flex;
          flex-direction: column;
          // gap: 4px;

          .name {
            color: $dark;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          .id {
            color: $secondary-gray-4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .checkbox {
        width: 20px;
        height: 20px;
        cursor: pointer;
        accent-color: $dark;
      }
    }

    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: $secondary-gray-4;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      border: 2px dashed $secondary-gray-2;
      border-radius: 12px;
      padding: 12px;
      margin: 20px 0px;
    }
  }

  .footer {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .modalbtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}
