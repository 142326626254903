@import "../../../variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include labelInput;
        input {
          color: $dark;
          border-radius: 4px;
          border: 1px solid rgba(128, 128, 128, 0.569);
        }
      }

      .participant {
        display: flex;
        flex-direction: column;
        gap: 8px;
        label {
          color: $secondary-gray-4;
          /* Secondary / Semibold */

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        input {
          padding: 12px 16px;
          border-radius: 8px;
          background: $primary-white;
          color: $dark;
          /* Body / Regular */

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          // &:hover {
          //   border: 1px solid $dark;
          // }

          // &:focus {
          //   border: 1px solid blue;
          // }
        }

        .autoComplete {
          // padding: 12px 16px;
          padding: 0px;
          border-radius: 8px;
          background: $primary-white;
          color: rgba(111, 114, 122, 0.8);

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .checkbox {
        display: flex;
        gap: 8px;
        margin-top: 10px;

        input {
          border: 2px solid $secondary-gray-4;
          width: 15px;
        }

        label {
          margin: 0;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
        }
      }

      .switch {
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        /* Body / Bold */

        // font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
        border-color: $dark;
        width: 80px;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
        width: 80px;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
