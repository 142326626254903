@import "src/variable/colors.scss";

@keyframes dots {
  0%,
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80%,
  100% {
    content: "...";
  }
}

@keyframes popUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.referModalBox {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .referModalTop {
    display: flex;
    padding: 16px 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;

    justify-content: space-between;

    .topL {
      display: flex;
      padding-top: 0px;
      P {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }

    .topR {
      display: flex;
      gap: 8px;
      .closeBtn {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      .requestBtn {
        text-transform: none;
        color: $dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 400px;
    align-items: center;

    .holdOnText {
      font-size: 1.2rem;
      color: $dark;
      font-weight: 600;
      .dots::after {
        content: "";
        display: inline-block;
        width: 1em;
        text-align: left;
        animation: dots 1.5s steps(4, end) infinite;
      }
    }
  }

  .successAnimation {
    display: flex;
    flex-direction: column;
    height: 400px;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      fill: #34a853;
      margin-bottom: 20px;
      animation: popUp 0.5s ease-out;
    }

    .successText {
      font-size: 1.2rem;
      color: $dark;
      font-weight: 600;
      animation: fadeIn 0.5s ease-out;
    }
  }

  .referModalBottom {
    display: flex;
    margin: 0px 55px;
    overflow-y: auto;
    max-height: 75vh;

    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;

      .bottomI,
      .bottomII,
      .bottomIII,
      .dropNdrag {
        display: flex;
        align-items: flex-start;
        gap: 70px;

        .Amount,
        .Merchant,
        .Category {
          display: flex;
          flex-direction: column;
          flex: 1 1;

          @include labelInput;

          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            display: none;
          }
        }

        .User,
        .purchasedBy {
          display: flex;
          flex-direction: column;
          flex: 1 1;

          .label {
            color: $secondary-gray-4;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          input {
            border: none;
          }

          label {
            color: rgba(111, 114, 122, 0.8);
          }
        }

        .receipt,
        .photo {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 27vw;

          label {
            color: $secondary-gray-4;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .addReceipt,
          .addPhoto {
            text-align: center;
            font-size: 12px;
            font-style: normal;
            line-height: normal;
            text-transform: none;
            background: $dark;
            display: flex;
            gap: 6px;
            border-radius: 6px;
          }
          .filepondWrapper {
            height: 100px;
            border-radius: 10px;
            border: 1px solid #aaa9a9;
            display: flex;
            overflow: hidden;
            overflow-x: auto;

            img {
              width: 100px;
            }

            .noData {
              display: flex;
              color: #878787;
              align-items: center;
              width: 110px;
              height: 30px;
              border-radius: 10px;
              justify-content: center;
              border: 1px dashed;
              padding: 20px 20px;
              margin: auto;
              z-index: 9;
            }
          }
        }
      }

      .bottomVI {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1 1;

        label {
          color: $secondary-gray-4;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        textarea {
          display: flex;
          resize: none;
          width: 93%;
          height: 55px;
          padding: 16px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid var(--text-98-a-1-b-3, #98a1b3);
          background: var(--body-ffffff, #fff);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: var(--text-98-a-1-b-3, #98a1b3);
            // text-align: right;
            // font-family: Nunito;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .saveBtn {
          display: flex;
          justify-content: flex-end;
          margin-right: 3%;

          button {
            background-color: $dark;
            text-transform: none;
          }
        }
      }
    }
  }

  .footer {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .modalbtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}
