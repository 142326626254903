@import "src/variable/colors.scss";

@mixin title {
  width: 171px;
  height: 26px;
  flex-shrink: 0;

  color: $dark;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}
@mixin input {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  // letter-spacing: 1.4px;
}

.referModalBox {
  .referModalTop {
    display: flex;
    // width: 960px;
    padding: 16px 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-radius: 12px 12px 0px 0px;
    background: var(--secondary-gray-1, #f7f7f9);
    justify-content: space-between;

    .topL {
      display: flex;
      // padding: 30px 55px;
      padding-top: 0px;
      P {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 21px */
      }
    }

    .topR {
      display: flex;
      gap: 8px;
      .closeBtn {
        text-transform: none;
        background: $dark;
      }

      .requestBtn {
        text-transform: none;
        color: $dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }

  .progress {
    display: flex;
    justify-content: space-around;
    height: 400px;
    align-items: center;
  }

  .referModalBottom {
    display: flex;
    margin: 0px 55px;
    overflow-y: auto;
    max-height: 75vh;

    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;

      .gridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        .gridItem {
          display: flex;
          flex-direction: column;

          .title {
            @include title;
          }

          .input {
            @include input;
          }

          &.hasAvatar {
            .input {
              display: flex;
              flex-direction: row;
              gap: 8px;
              align-items: center;

              .inputL {
                img {
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                }
              }

              .inputR {
                display: flex;
                flex-direction: column;
                .name {
                  @include input;
                }
                .walletName,
                .email {
                  color: #64748b;
                }
              }
            }
          }

          &.amount .input {
            color: inherit;
          }

          &.gst {
            input {
              display: flex;
              width: 60%;
              height: 40px;
              padding: 0px 16px;
              align-items: center;
              gap: 10px;
              flex-shrink: 0;
              border-radius: 8px;
              border: 1px solid var(--body-e-1-e-2-e-6, #e1e2e6);
              background: #fff;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                display: none;
              }
            }
          }
        }
      }

      .dropNdrag {
        display: flex;
        justify-content: flex-start;
        gap: 91px;

        .bottomIV,
        .bottomV {
          display: flex;
          flex-direction: column;
          align-items: center;

          .receipt,
          .photo {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .title {
              @include title;
            }
            .addReceipt,
            .addPhoto {
              text-align: center;
              font-size: 12px;
              font-style: normal;
              line-height: normal;
              text-transform: none;
              background: $dark;
              display: flex;
              gap: 6px;
              border-radius: 6px;
            }
            .filepondWrapper {
              width: 300px;
              height: 158px;
              border-radius: 10px;
              border: 1px solid #aaa9a9;
              display: flex;
              overflow: hidden;
              overflow-x: auto;

              img {
                width: 100px;
              }

              .noData {
                display: flex;
                color: $secondary-gray-4;
                align-items: center;
                width: 110px;
                height: 48px;
                border-radius: 10px;
                justify-content: center;
                border: 1px dashed;
                padding: 20px 20px;
                margin: 34px 73px;
                text-align: center;
                background-color: rgba(255, 255, 255, 0);
                z-index: 9;
              }
            }
          }
        }
      }
      .bottomVI {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: -10px;

        .title {
          @include title;
        }
        textarea {
          display: flex;
          resize: none;
          width: 93%;
          height: 96px;
          padding: 16px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid var(--text-98-a-1-b-3, #98a1b3);
          background: var(--body-ffffff, #fff);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);

          &::placeholder {
            color: var(--text-98-a-1-b-3, #98a1b3);
            // text-align: right;
            // font-family: Nunito;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .saveBtn {
          display: flex;
          justify-content: flex-end;
          margin-right: 3%;

          button {
            background-color: $dark;
            text-transform: none;
          }
        }
      }
    }

    #scrollBarH::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    #scrollBarH::-webkit-scrollbar {
      height: 2px;
      background-color: #f5f5f5;
    }

    #scrollBarH::-webkit-scrollbar-thumb {
      background-color: #919191;
    }
  }

  @include scrollbarDesign2;

  @include scrollbarDesignH;

  .footer {
    .saveBtn {
      display: flex;
      justify-content: flex-end;
      margin-right: 5%;
      margin-top: 15px;
      margin-bottom: 15px;

      button {
        background-color: $dark;
        text-transform: none;
      }
    }
  }
}

.noteHistory {
  display: flex;
  align-items: flex-start;
  width: 99%;
  flex-direction: column;
  flex-wrap: wrap;

  .title {
    color: $dark;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .noteContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90%;
    height: 130px;
    padding: 11px 16px;
    border-radius: 12px;
    border: 1px solid #dde0e5;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(51, 65, 86, 0.08);
    margin: 10px;
    align-items: center;
    min-width: 230px;
    margin: 10px;
    justify-content: space-around;

    .noteBottom {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .item {
        display: flex;
        gap: 4px;
        text-align: left;
        color: $secondary-gray-4;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        .itemTitle {
          color: $dark;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }

      h2 {
        color: #1e293b;
        font-style: normal;
        font-size: 40px;
      }
    }

    p {
      display: flex;
      flex-direction: column;
      align-self: stretch;

      color: $secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
    }
  }

  .transCardContainer {
    justify-content: center;
  }

  .latesttransCardContainer {
    gap: 6px;
  }

  .seeMoreContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    button {
      background-color: $dark;
      text-transform: none;
    }
  }
}
