@import "src/variable/colors.scss";

.home {
  display: flex;
  flex-direction: column;
  margin: 33px 15px 15px 25px;
  gap: 45px;
  background: $gray-100;

  .cardContainer {
    display: flex;
    flex-direction: column;
    max-width: 360px;

    width: 82%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 13px 15px;
    gap: 14px;

    .card {
      display: flex;
      position: relative;
      padding: 29px 26px;
      border-radius: 10px;
      gap: 20px;
      align-items: center;
      background: linear-gradient(77deg, rgba(15, 23, 42, 0.45) -66.4%, rgba(15, 23, 42, 0.01) 111.17%);

      .indicator {
        display: flex;
        flex-direction: column;
        gap: 18px;
        .greenCircle {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: rgba(52, 168, 83, 0.2);
        }
        .greenactive {
          background-color: #34a853;
          border: 1px solid #000;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
        .redCircle {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: rgba(234, 67, 53, 0.3);
        }
        .redactive {
          background-color: #cd2626;
          border: 1px solid #000;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
      .cardContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 11px;
        width: 100%;
        .walletName,
        .balance {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .cardno {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .caution {
        display: flex;
        position: absolute;
        bottom: 2px;
        right: 8px;
        font-size: 11px;
        font-weight: 500;
        color: #666e80;
      }
    }
    .wallet {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .selectOption {
        display: flex;
      }

      .btn {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: $dark;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #ffffff;
        text-transform: none;
        font-weight: 600;
      }
    }
  }

  .cardWpagination {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    .cardContainerOver {
      max-width: 320px;
      width: 20rem;
    }
  }

  .CircularProgress {
    display: flex;
    width: 300px;
    height: 240px;
    justify-content: center;
    align-items: center;
  }

  .recentActivityContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .recentActivity {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .recentActivityOver {
    margin-left: 3rem;
  }
}

.container {
  display: flex;
}

.pagination {
  display: flex;
  justify-content: center;
  // margin-top: -75px;
  font-size: 11px;
  margin-left: -30px;
}

@media screen and (max-width: 440px) {
  .home {
    margin: 33px 0px 15px 25px;
    .cardContainer {
      width: 60vw;

      .card {
        .cardContent {
          .walletName,
          .balance {
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .cardno {
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .caution {
          font-size: 8px;
        }
      }
    }
    .cardContainerOver {
      max-width: 320px;
      width: auto;
      margin-right: 10px;
    }
    .recentActivityOver {
      margin-left: 0px;
    }
  }

  .carouselContainer {
    margin: 0px !important;
    height: 330px !important;
  }
}
