@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    padding: 24px 40px;
    width: 600px;
    background: $secondary-gray-1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    h5 {
      color: $primary-black;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .body {
    padding: 24px 40px;
    width: 600px;
    color: $primary-black;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btnL {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .removeBtn {
          display: flex;
          /* height: 40px; */
          padding: 0px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #fa0000;
          box-shadow:
            0px 4px 8px 0px rgba(163, 59, 8, 0.08),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #fa0000;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: none;
        }

        .reportBtn {
          color: #324056;
          text-align: center;

          /* Body / Bold */

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-transform: none;
        }
      }

      .btnR {
        .sendBtn {
          display: flex;
          /* height: 48px; */
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #1e293b;

          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
      }
    }
  }
}

.progress {
  height: 300px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
