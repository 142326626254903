@import "src/variable/colors.scss";

* {
  margin: 0;
  padding: 0;
}

.orgPage {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;

  .orgPageWelCard {
    display: flex;
    margin-top: 33px;
    background: url("../../assets/images/pinkBg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .welCardLOver {
      height: 75px !important;
      width: 75px !important;
    }
  }
  .orgPageHead {
    display: flex;
    // max-width: 1166px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin: 24px;

    .walletConnectCardOver {
      padding: 20px;
      .walletMemberOver {
        min-height: 16.1rem;
        max-height: 265px;
      }
    }
  }
  .orgPageBottom {
    display: flex;
    align-items: flex-start;
    // gap: 30px;
    margin: 17px;
    justify-content: space-between;
    margin-top: 0;
    height: auto;
    gap: 10px;

    .connectCards {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .recentTransOver {
      max-width: 50vw;
    }

    .userConnectCardOver {
      margin: 10px;
      .userMemberOver {
        min-height: 19.5rem;
        max-height: 60vh;
      }
    }
  }

  .circularProgress {
    width: 25rem;
    height: 27rem;
    align-items: center;
  }
}

@media screen and (max-width: 886px) {
  .orgPageBottom {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;

    .recentTransOver {
      max-width: 100vw !important;
    }
  }
}
