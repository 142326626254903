@import "src/variable/colors.scss";

.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }

      .addUser {
        display: flex;
        flex: row;
        gap: 50px;
        .byWallet, .byUser {
          display: flex;
          flex-direction: column;
          gap: 18px;
          width: 250px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: flex-end;

          label {
            color: $dark;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          input {
            padding: 12px 16px;
            border-radius: 8px;
            background: $primary-white;
            color: $dark;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .autoComplete {
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

       .statusType {
        display: flex;
        gap: 16px;
        flex-direction: column;
    

         .status, .type {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 250px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .byUserHead {
            display: flex;
            justify-content: space-between;

            label {
                color: $dark;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
          }
          input {
            padding: 12px 16px;
            border-radius: 8px;
            background: $primary-white;
            color: $dark;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          .autoComplete {
            padding: 0px;
            border-radius: 8px;
            background: $primary-white;
            color: rgba(111, 114, 122, 0.8);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .referModalBox {
    .referModalTop {
      form {
        .addUser {
          flex-direction: column;
          gap: 16px;
          .byWallet,
          .byUser {
            width: 100%;
          }
        }
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
