@import "../../../variable/colors.scss";

.modalShadow {
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9 !important;
  .modalui {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // max-width: 800px;
    // max-width: 40vw;
    width: auto;

    // width: auto;
    background-color: $primary-white;
    border-radius: 12px;
    border: none;
    box-shadow:
      0px 2px 4px 0px rgba(0, 0, 0, 0.1),
      0px 1px 10px 0px rgba(0, 0, 0, 0.05);

    // background: $gray-100;
  }
}

@media screen and (max-width: 1310px) {
}
