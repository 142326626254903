@import "src/variable/colors.scss";
.referModalBox {
  .referModalTop {
    margin: 24px 40px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        h5 {
          color: $primary-black;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        button {
          border: none;
          background-color: $primary-white;
          cursor: pointer;
        }
      }
      .detailContainer {
        display: flex;
        gap: 16px;
        flex-direction: column;

        .userInfo,
        .walletInfo {
          display: flex;
          flex-direction: column;
          gap: 3px;

          box-shadow: 0px 4px 4px #00000040;
          border: 1px solid #0000004c;
          background: $primary-white;
          padding: 16px;
          border-radius: 12px;

          @include labelInput;

          .userdata,
          .walletdata {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            gap: 3px;

            .label {
              display: flex;
              gap: 8px;
              justify-content: space-between;

              label {
                white-space: nowrap;
              }

              .value {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }
            }
          }
        }
      }
      .amountCard {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #0000004c;
        border-radius: 10px;
        box-shadow: 0px 4px 4px #00000040;
        display: flex;
        flex-direction: column;
        gap: 18px;
        height: 158px;
        justify-content: center;
        overflow: hidden;
        padding: 0px 35px 2px;

        .title {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: normal;
          text-align: center;
        }
        .amount {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .amtValue {
            display: flex;
            align-items: center;
            background-color: #d9d9d9;
            border: 1px solid #0000004c;
            border-radius: 10px;
            box-shadow: 0px 4px 4px #00000040;
            gap: 10px;
            height: 25px;
            justify-content: center;
            padding: 2px 10px;
          }
        }
        .amountInput {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            border: 1px solid #0000004c;
            box-shadow: 0px 4px 4px #00000040;
            border-radius: 10px;
            padding: 11px 25px;
            gap: 10px;
          }
        }
      }
    }
  }

  .referModalBottom {
    background: $secondary-gray-1;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .referModalBtn {
      padding: 16px 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancelBtn {
        border-radius: 8px;
        justify-content: center;
        color: $dark;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
      .sendBtn {
        display: flex;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: $dark;

        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }
}

.error {
  display: flex;
  color: rgb(254, 44, 44);
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  line-height: 20.8px;
  align-items: center;

  .errIcon {
    width: 15px;
  }
}
